<template>
  <div class="row justify-content-center" id="Login">
    <v-btn
      cols="12"
      color="pink"
      fab
      dark
      small
      style="position:absolute; top:10px; left:10px; z-index:3;"
      @click="back"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div class="col-md-12 log-form">
      <v-img
        style="width: 100%; height: 100%; position: absolute;"
        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      ></v-img>
      <div
        style="position: relative; right:100px; z-index:2; display: flex; flex-direction: row;"
      >
        <v-btn
          style="height:50px;width:50px; margin-right: 10px;"
          elevation="5"
          fab
          x-large
          color="white"
          class=" rounded-circle"
          ><v-img
            alt="sanqua multi international"
            class=" rounded-circle"
            contain
            src="../../../public/sanquamulti.png"
            transition="scale-transition"
            style="height:50px;width:50px; "
        /></v-btn>
        <h5 style="line-height:20px; margin-top:12px; color: white;">
          Sanqua
          <h4 style="color:#37474F">Change Password</h4>
        </h5>
      </div>
      <v-card
        elevation="10"
        class="mx-auto"
        max-width="444"
        style="padding:30px;"
      >
        <h3
          style="display: flex; justify-content: flex-start; align-items:center; width: 200px;"
        >
          Change Here
        </h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum,
          repellendus.
        </p>
        <v-alert
          v-if="isChange !== null"
          dense
          :type="isChange === false ? 'error' : 'success'"
          style="border-bottom:1px solid black; fontSize: 12px; border-radius: 5px;"
        >
          {{ alertMsg }}
        </v-alert>
        <b-form @submit.stop.prevent>
          <label
            for="old-password"
            style="width:100%; text-align:left; padding-left: 10px"
            >Old Password</label
          >
          <b-form-input
            v-model="form.old_password"
            type="password"
            id="old-password"
            style="margin-bottom: 20px;"
          ></b-form-input>
          <label
            for="new-password"
            style="width:100%; text-align:left; padding-left: 10px"
            >New Password</label
          >
          <b-form-input
            v-model="form.new_password"
            type="password"
            id="new-password"
            style="margin-bottom: 20px;"
          ></b-form-input>
          <label
            for="confirm-password"
            style="width:100%; text-align:left; padding-left: 10px"
            >Confirm New Password</label
          >
          <b-form-input
            v-model="form.confirm_new_password"
            type="password"
            id="confirm-password"
            style="margin-bottom: 20px;"
          ></b-form-input>
          <div class="col-12" style="text-align: right; padding: 0;">
            <button @click="submitChange">Submit</button>
          </div>
        </b-form>
      </v-card>
      <hr />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'change',
  data() {
    return {
      isChange: null,
      alertMsg: '',
      token: null,
      email: null,
      form: {
        email: '',
        old_password: '',
        new_password: '',
        confirm_new_password: ''
      }
    }
  },
  created() {
    this.form.email = this.getUserProfile.employee.email
  },
  components: {},
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions([
      'tokenValidation',
      'verifyData',
      'changePasswordNow',
      'verifyData'
    ]),
    back() {
      this.$router.push('/')
    },
    resetForm() {
      this.form.old_password = ''
      this.form.new_password = ''
      this.form.confirm_new_password = ''
    },

    submitChange() {
      if (this.form.old_password === '') {
        this.isChange = false
        this.alertMsg = 'Old password must be filled'
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else if (this.form.new_password.length < 6) {
        this.isChange = false
        this.alertMsg = 'New password at least must be 6 character'
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else if (this.form.confirm_new_password !== this.form.new_password) {
        this.isChange = false
        this.alertMsg = "Password doesn't match"
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else {
        this.changePasswordNow([this.form])
          .then(result => {
            if (result.data.status_code === '00') {
              this.isChange = true
              this.resetForm()
              this.alertMsg = result.data.status_msg
              setTimeout(() => {
                this.isChange = null
              }, 4000)
            } else {
              this.isChange = false
              this.alertMsg = result.data.status_msg
              setTimeout(() => {
                this.isChange = null
              }, 4000)
            }
          })
          .catch(error => {
            this.isChange = false
            this.alertMsg = error
            setTimeout(() => {
              this.isChange = null
            }, 4000)
          })
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
